import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/feedback/type/feedback',
      name: 'feedback1',
      component: () => import('@/views/feedbacks.vue')
    },
    {
      path: '/ticket/:id',
      name: 'ticket',
      component: () => import('@/views/ticket.vue')
    },
    {
      path: '/feedback/type/ticket',
      name: 'feedback2',
      component: () => import('@/views/feedbacks.vue')
    },
    {
      path: '/calls',
      name: 'calls',
      component: () => import('@/views/calls.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users.vue')
    },
    {
      path: '/resellers',
      name: 'resellers',
      component: () => import('@/views/resellers.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings.vue')
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders.vue')
    },
    {
      path: '/freedays',
      name: 'freedays',
      component: () => import('@/views/freedays.vue')
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('@/views/services.vue')
    },
    {
      path: '/todo',
      name: 'todo',
      component: () => import('@/views/resellers_todo.vue')
    },
    {
      path: '/visits',
      name: 'visits',
      component: () => import('@/views/resellers_visits.vue')
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/resellers_calendar.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/logout.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
